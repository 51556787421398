<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/configuraciones/giros/crear">
        Crear giro
      </c-primary-button>
    </div>
    <c-card title="Giros">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Giro</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="giro in giros" :key="giro.id">
            <td>{{ giro.name }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/configuraciones/giros/${giro.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteGiro(giro.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/giros";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      giros: [],
    };
  },
  mounted() {
    this.getGiros();
  },
  methods: {
    getGiros() {
      all()
        .then((response) => {
          this.giros = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las frecuencias");
        });
    },
    deleteGiro(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getGiros();
      });
    },
  },
};
</script>
