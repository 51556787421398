import axios from "@/plugins/Axios";

const model = "giros";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(giro) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, giro);
}

function update(id, giro) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, giro);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}
export { all, find, create, update, remove };
